var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-1"},[_c('h6',{staticClass:"text-overline"},[_vm._v("Desconto para o evento")]),_c('v-card',{staticClass:"mb-2 pa-3 px-4 rounded-lg"},[_c('div',{staticClass:"mb-1 d-flex align-center justify-space-between"},[_c('h6',{},[_vm._v(" "+_vm._s(_vm.party.name)+" ")]),_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" Aplicado em todos os ingressos ")])],1),_c('v-row',{staticClass:"px-1",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Reais","type":"number","hide-details":"","prefix":"R$","outlined":"","dense":""},model:{value:(_vm.rules.party.fees.fixed),callback:function ($$v) {_vm.$set(_vm.rules.party.fees, "fixed", $$v)},expression:"rules.party.fees.fixed"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Porcentagem","type":"number","hide-details":"","suffix":"%","rules":_vm.inputRules.percentage,"min":"0","max":"100","outlined":"","dense":""},model:{value:(_vm.rules.party.fees.percentage),callback:function ($$v) {_vm.$set(_vm.rules.party.fees, "percentage", $$v)},expression:"rules.party.fees.percentage"}})],1)],1)],1),_c('h6',{staticClass:"text-overline"},[_vm._v("Desconto por setor")]),_c('v-expansion-panels',{staticClass:"px-1"},_vm._l((_vm.ticketGroups),function(ticketGroup){return _c('v-expansion-panel',{key:ticketGroup.id},[_c('v-expansion-panel-header',[_c('b',{staticClass:"flex-grow-1"},[_vm._v(_vm._s(ticketGroup.name))]),_c('div',{staticClass:"flex-grow-0"},[(
              _vm.rules.party.fees.fixed ||
              _vm.rules.ticketGroups[ticketGroup.id].fixed
            )?_c('v-chip',{staticClass:"mr-1",attrs:{"small":"","label":"","color":_vm.rules.ticketGroups[ticketGroup.id].fixed ? 'primary' : undefined}},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.rules.ticketGroups[ticketGroup.id].fixed || _vm.rules.party.fees.fixed),true))+" ")]):_vm._e(),_c('v-chip',{staticClass:"mr-2",attrs:{"small":"","label":"","color":_vm.rules.ticketGroups[ticketGroup.id].percentage
                ? 'primary'
                : undefined}},[_vm._v(" "+_vm._s(_vm.rules.ticketGroups[ticketGroup.id].percentage || _vm.rules.party.fees.percentage || 0)+"% ")])],1)]),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Desconto em reais","type":"number","min":"0","rules":_vm.inputRules.fixed,"outlined":"","dense":"","hide-details":""},model:{value:(_vm.rules.ticketGroups[ticketGroup.id].fixed),callback:function ($$v) {_vm.$set(_vm.rules.ticketGroups[ticketGroup.id], "fixed", $$v)},expression:"rules.ticketGroups[ticketGroup.id].fixed"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Desconto em porcentagem","type":"number","min":"0","max":"100","outlined":"","dense":"","hide-details":""},model:{value:(_vm.rules.ticketGroups[ticketGroup.id].percentage),callback:function ($$v) {_vm.$set(_vm.rules.ticketGroups[ticketGroup.id], "percentage", $$v)},expression:"rules.ticketGroups[ticketGroup.id].percentage"}})],1)],1),_c('v-divider',{staticClass:"my-2"}),_c('h6',{staticClass:"text-overline"},[_vm._v("Desconto por lote")]),_c('div',{staticClass:"d-flex flex-column gap-2"},_vm._l((ticketGroup.TicketBlock),function(ticketBlock){return _c('v-card',{key:ticketBlock.id,staticClass:"pa-2",attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex flex-grow-1"},[_c('b',{staticClass:"text-start flex-grow-1"},[_vm._v(_vm._s(ticketBlock.name))]),(
                  _vm.rules.ticketBlocks[ticketBlock.id].fixed ||
                  _vm.rules.ticketGroups[ticketGroup.id].fixed
                )?_c('v-chip',{staticClass:"mr-1",attrs:{"small":"","label":"","color":_vm.rules.ticketBlocks[ticketBlock.id].fixed
                    ? 'primary'
                    : undefined}},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.rules.ticketBlocks[ticketBlock.id].fixed || _vm.rules.ticketGroups[ticketGroup.id].fixed || _vm.rules.party.fees.fixed),true))+" ")]):_vm._e(),_c('v-chip',{attrs:{"small":"","label":"","color":_vm.rules.ticketBlocks[ticketBlock.id].percentage
                    ? 'primary'
                    : undefined}},[_vm._v(" "+_vm._s(_vm.rules.ticketBlocks[ticketBlock.id].percentage || _vm.rules.ticketGroups[ticketGroup.id].percentage || _vm.rules.party.fees.percentage || 0)+"% ")])],1),_vm._v(" "+_vm._s(_vm._f("currency")(ticketBlock.price,true))+" "),_c('v-row',{staticClass:"mx-0",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Desconto em reais","type":"number","min":"0","rules":_vm.inputRules.fixed,"outlined":"","dense":"","hide-details":""},model:{value:(_vm.rules.ticketBlocks[ticketBlock.id].fixed),callback:function ($$v) {_vm.$set(_vm.rules.ticketBlocks[ticketBlock.id], "fixed", $$v)},expression:"rules.ticketBlocks[ticketBlock.id].fixed"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Desconto em porcentagem","type":"number","min":"0","max":"100","outlined":"","rules":_vm.inputRules.percentage,"dense":"","hide-details":""},model:{value:(_vm.rules.ticketBlocks[ticketBlock.id].percentage),callback:function ($$v) {_vm.$set(_vm.rules.ticketBlocks[ticketBlock.id], "percentage", $$v)},expression:"rules.ticketBlocks[ticketBlock.id].percentage"}})],1)],1)],1)}),1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }